import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

axios.defaults.baseURL = store.state.apiUrl;
axios.defaults.withCredentials = true;

axios
  .get(store.state.csrfUrl)
  .then(() => {})
  .catch((e) => {
    console.error(e);
  });

const token = localStorage.getItem("token");
if (token && token != null && token != "null") {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
