var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-nav"},[_c('b-container',[_c('b-row',{attrs:{"align-h":"between","align-v":"center"}},[_c('b-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"main-nav-links"},_vm._l((_vm.links),function(item,index){return _c('a',{key:index,staticClass:"main-nav-link",class:[item.routePath == _vm.$route.path ? 'active' : ''],on:{"click":() => {
                if (item.routePath != _vm.$route.path)
                  _vm.$router.push({ path: item.routePath });
              }}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('b-col',{attrs:{"cols":"auto"}},[_c('div',[(!_vm.user && !_vm.loading)?_c('a',{on:{"click":() => {
                if (_vm.$route.name != 'Auth') _vm.$router.push({ name: 'Auth' });
              }}},[_vm._v("Аккаунт")]):_vm._e(),(_vm.user)?_c('a',{staticClass:"main-nav-link",class:[_vm.$route.name == 'Profile' ? 'active' : ''],on:{"click":() => {
                if (_vm.$route.name != 'Profile')
                  _vm.$router.push({ name: 'Profile' });
              }}},[_vm._v(_vm._s(_vm.user.name))]):_vm._e()])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }