<template>
  <div>
    <div class="main-nav-padding"></div>

    <b-container>
      <b-row align-h="center">
        <b-col cols="12" md="3" class="chat-block p-2">
          <div>
            <b-form-group label="Текущий чат">
              <b-form-input
                v-if="activeChat"
                class="mb-4"
                v-model="activeChat.title"
                @change="updateChat()"
              ></b-form-input>

              <b-form-input
                v-else
                disabled
                class="mb-4"
                @change="updateChat()"
              ></b-form-input>
            </b-form-group>
          </div>

          <div>
            <b-button
              v-for="(chat, index) in chats"
              :key="index"
              variant="outline-primary"
              block
              @click="selectChat(chat)"
            >
              {{ chat.title }}
            </b-button>

            <b-form @submit="createChat">
              <b-row class="pt-2">
                <b-col
                  ><b-form-input v-model="newChat.title"></b-form-input>
                </b-col>

                <b-col cols="auto" class="pl-0">
                  <b-button type="submit" variant="outline-primary">+</b-button>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </b-col>

        <b-col cols="12" md="1" class="">
          <div>
            <b-button class="on-top-button" size="sm" @click="scrollToTop()"
              ><img :src="require(`@/assets/arrow-up-circle.svg`)"
            /></b-button>

            <b-button
              class="on-bottom-button"
              size="sm"
              @click="scrollToBottom()"
              ><img :src="require(`@/assets/arrow-down-circle.svg`)"
            /></b-button>
          </div>
        </b-col>

        <b-col cols="12" md="7" class="chat-main chat-block">
          <div>
            <div id="top"></div>

            <div id="gptChatResult">
              <b-col
                cols="12"
                v-for="(completion, index) in completions"
                :key="index"
              >
                <b-row align-h="end">
                  <b-col cols="auto" class="chat-message chat-question">
                    <p
                      class="chat-message-data"
                      v-html="completion.question"
                    ></p>
                  </b-col>
                </b-row>

                <b-row align-h="start">
                  <b-col cols="auto" class="chat-message chat-answer">
                    <p class="chat-message-data" v-html="completion.answer"></p>
                  </b-col>
                </b-row>
              </b-col>
            </div>

            <div id="bottom"></div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <div class="new-message-block">
      <b-container>
        <b-row align-h="center">
          <b-col cols="12" lg="8">
            <b-form>
              <b-row align-h="center" align-v="center">
                <b-col>
                  <p class="model-name">Используемая модель: {{ modelName }}</p>

                  <b-form-textarea
                    required
                    size="sm"
                    v-model="gptMessage"
                    @change="saveMessage('gptMessage', gptMessage)"
                    :rows="1"
                    :max-rows="10"
                    :disabled="!this.activeChat"
                    @keydown.enter.exact.prevent="createChatCompletion()"
                  ></b-form-textarea>

                  <!-- <p>{{ gptMessage.length }}</p> -->
                </b-col>

                <b-col cols="auto" class="pl-0">
                  <b-button
                    size="lg"
                    class="send-message"
                    @click="createChatCompletion()"
                    :disabled="loading"
                    variant="primary"
                    type="button"
                    ><img
                      class="send-message-img"
                      :src="require(`@/assets/send-2.svg`)"
                  /></b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "ChatView",
  data() {
    return {
      completions: [],
      gptMessage: "",
      modelName: "gpt-3.5-turbo",

      chats: [],
      newChat: {
        title: "",
      },

      activeChat: null,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.data,
      loading: (state) => state.loading,
      openai: (state) => state.openai.openai,
    }),
  },
  methods: {
    async getChats() {
      try {
        this.$store.commit("LOADING", true);

        let chats = await axios.get("chats");
        this.chats = chats.data;

        this.$store.commit("LOADING", false);
      } catch (e) {
        this.$store.commit("LOADING", false);
        this.$bvToast.toast(`${e.response.data.error.message}`);
      }
    },
    async createChat(ev) {
      ev.preventDefault();

      try {
        this.$store.commit("LOADING", true);

        await axios.put("chats", { title: this.newChat.title });
        await this.getChats();

        this.$store.commit("LOADING", false);
      } catch (e) {
        this.$store.commit("LOADING", false);
        this.$bvToast.toast(`${e.response.data.error.message}`);
      }
    },
    async updateChat() {
      try {
        this.$store.commit("LOADING", true);

        await axios.patch(`chats/${this.activeChat.id}`, {
          title: this.activeChat.title,
        });

        this.$store.commit("LOADING", false);
      } catch (e) {
        this.$store.commit("LOADING", false);
        this.$bvToast.toast(`${e.response.data.error.message}`);
      }
    },
    async selectChat(chat) {
      this.activeChat = chat;

      this.completions = [];

      await this.getCompletions();
    },
    async getCompletions() {
      try {
        this.$store.commit("LOADING", true);

        let completions = await axios.get(`completions/${this.activeChat.id}`);
        this.completions = completions.data.data;

        setTimeout(() => {
          this.scrollToBottom();
        }, 1);

        this.$store.commit("LOADING", false);
      } catch (e) {
        this.$store.commit("LOADING", false);

        this.$bvToast.toast(`${e.response.data.error.message}`);
      }
    },
    async createChatCompletion() {
      let history = [];

      let comp = this.completions[this.completions.length - 1];

      if (comp) {
        history.push({
          role: "user",
          content: comp.question,
        });

        history.push({
          role: "system",
          content: comp.answer,
        });
      }

      history.push({
        role: "user",
        content: this.gptMessage,
      });

      let newCompletion = {
        question: this.gptMessage,
        answer: "...",
      };
      this.completions.push(newCompletion);

      this.scrollToBottom();

      try {
        this.$store.commit("LOADING", true);

        let completion = await this.openai.createChatCompletion({
          model: this.modelName,
          messages: history,
        });
        completion = completion.data.choices[0].message.content;

        newCompletion = this.completions.pop();
        newCompletion.answer = completion;

        this.completions.push(newCompletion);

        // save new completion in DB
        await axios.put(`completions/${this.activeChat.id}`, newCompletion);

        // clean input
        this.gptMessage = "";
        localStorage.removeItem("gptMessage");

        this.$store.commit("LOADING", false);
      } catch (e) {
        this.$store.commit("LOADING", false);
        console.error(e);
        this.$bvToast.toast(`${e.response.data.error.message}`);
      }

      this.scrollToBottom();
    },

    scrollToTop() {
      let bottom = this.$el.querySelector("#top");

      bottom.scrollIntoView({ behavior: "smooth" });
    },
    scrollToBottom() {
      let bottom = this.$el.querySelector("#bottom");

      bottom.scrollIntoView({ behavior: "smooth" });
    },

    saveMessage(key, value) {
      localStorage.setItem(key, value);
    },
  },
  async mounted() {
    await this.$store.dispatch("setupOpenai");

    await this.getChats();
    await this.getCompletions();

    // saved message
    let gptMessage = localStorage.getItem("gptMessage");
    if (gptMessage) this.gptMessage = gptMessage;
  },
};
</script>

<style lang="scss" scoped>
body,
html {
  background-color: #fff;
}
#bottom {
  height: 1px;
  position: relative;
}
.chat-message {
  max-width: 60%;

  border-radius: $radius;
  margin-bottom: 15px;
  padding: 8px 14px;
}
.chat-message-data {
  margin: 0;
  white-space: pre-wrap;
}

.model-name {
  font-size: 13px;
}

@media only screen and (max-width: 640px) {
  .chat-message {
    max-width: 80%;
  }
}

.chat-main {
  padding-top: 5vh;
  padding-bottom: 10vh;
}

.chat-block {
  margin: 5px;
  position: relative;
  height: 75vh;
  overflow: scroll;

  outline-style: solid;
  outline-width: 2px;
  outline-color: rgba($color: $zinc100, $alpha: 1);
}

.chat-question {
  background-color: rgba(#007bff, 1);
  color: #fff;
}
.chat-answer {
  background-color: rgba(#efefef, 1);
}

.textarea-base-outline {
  background-color: unset;

  border: 2px solid;
  border-color: rgba($white, 0.1);

  resize: none;

  border-radius: $radius;

  width: 100%;

  padding: 20px 30px;

  color: rgba($white, 0.7);
  font-weight: 300;
  font-size: 18px;
}
.textarea-base-outline:focus {
  border-color: rgba($white, 0.3);
  outline: none;
}

.gpt-response {
  width: 60vw;
  text-align: start;
  font-size: 16px;
  font-weight: 300;
  color: rgba($white, 0.7);

  margin-bottom: 15px;

  padding: 20px 30px;
  border: 2px solid;
  border-radius: $radius;
  border-color: rgba($accent, 0.1);
  background-color: rgba($accent, 0.02);
}

.gpt-question {
  width: 60vw;

  text-align: start;
  font-size: 16px;
  font-weight: 300;
  color: rgba($white, 0.6);

  margin-bottom: 15px;

  padding: 20px 30px;
  border: 2px solid;
  border-radius: $radius;
  border-color: rgba($white, 0.1);
  background-color: rgba($white, 0.02);
}

.chat-result {
  overflow-y: scroll;

  border-left: 2px solid;
  border-right: 2px solid;
  // border-radius: $radius;
  border-color: rgba($white, 0.1);

  margin-top: 5vh;
  margin-bottom: 2vh;
  padding: 15px;
}

.model-block {
  margin-bottom: 10vh;
}
.model-result {
  padding-top: 5vh;
}
.model-title {
  font-weight: 700;
  font-size: 20px;
  color: rgba($white, 0.3);
  text-align: start;

  padding-top: 10px;
  padding-bottom: 10px;
}

.send-model-btn {
  border: 1px solid;
  border-color: rgba($white, 0.1);
  border-radius: $radius;

  padding: 7px 30px;

  margin-top: 10px;

  font-weight: 400;
  font-size: 16px;
  color: rgba($white, 0.7);
}
.send-model-btn:focus {
  outline: none;
  border-color: rgba($white, 0.5);
  background-color: rgba($white, 0.05);
}

.content-length-description {
  font-weight: 700;
  font-size: 14px;
  color: rgba($white, 0.3);

  text-align: start;
  margin-left: 30px;
}

.dalle-generated-img {
  margin: 5px;
}

.line {
  height: 1px;
  width: 100%;
  background-color: rgba($white, 0.1);
}

.new-message-block {
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;

  background-color: rgba(#b9b9b9, 0.3);
  backdrop-filter: blur(10px);

  padding-top: 10px;
  padding-bottom: 10px;
  height: 12vh;
}
.send-message {
  position: relative;
  height: 42px;
  width: 42px;
  border-radius: 100%;
}
.send-message-img {
  height: 100%;
  width: 100%;

  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 7px;
}

.on-top-button {
  position: absolute;
  z-index: 100;
  right: 10px;
  top: 40%;
  transform: translate(0, -50%);

  background-color: rgba(#000, 0.2);
  backdrop-filter: blur(5px);

  padding: 5px;

  border: none;
}

.on-bottom-button {
  position: absolute;
  z-index: 100;
  right: 10px;
  bottom: 40%;
  transform: translate(0, -50%);

  background-color: rgba(#000, 0.2);
  backdrop-filter: blur(5px);

  padding: 5px;

  border: none;
}
</style>
