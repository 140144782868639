import Vue from "vue";
import VueRouter from "vue-router";
import MainView from "../views/ChatView.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
  },
  {
    path: "/auth",
    name: "Auth",
    meta: { auth: false },
    component: () => import("../views/AuthView.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { auth: true },
    component: () => import("../views/ProfileView.vue"),
  },
  {
    path: "/",
    name: "Chat",
    meta: { auth: true },
    component: MainView,
  },
  {
    path: "/dalle",
    name: "Dalle",
    meta: { auth: true },
    component: () => import("../views/DalleView.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    meta: { auth: true, admin: true },
    component: () => import("../views/AdminView.vue"),
  },
];

let router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  let user;
  const token = localStorage.getItem("token");

  if (token) {
    try {
      store.commit("LOADING", true);
      await store.dispatch("getUser");
      user = store.state.user.data;

      store.commit("LOADING", false);
    } catch (e) {
      store.commit("LOADING", false);

      console.error(e);
    }
  }

  if (to.meta.auth == true && !user) {
    next({ name: "Auth" });
  }

  if (to.meta.admin == true && !user.admin) {
    next({ name: "/" });
  }

  next();
});

export default router;
