import Vue from "vue";
import Vuex from "vuex";
import user from "@/store/user.js";
import openai from "@/store/openai.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    csrfUrl: "https://openai.s-materials.ru/laravel/public/sanctum/csrf-cookie",
    apiUrl: "https://openai.s-materials.ru/laravel/public/api/",
    storeUrl: "https://openai.s-materials.ru/laravel/storage/",

    b24Url: "https://dbmaterials.bitrix24.ru/",
    clientId: "local.6509188fba8204.25497706",
  },
  getters: {},
  mutations: {
    LOADING(state, payload) {
      state.loading = payload;
    },
  },
  actions: {},
  modules: {
    user,
    openai,
  },
});
