import axios from "axios";
import { Configuration, OpenAIApi } from "openai";

export default {
  state: {
    openai: null,
  },
  mutations: {
    SET_OPENAI(state, payload) {
      state.openai = payload;
    },
  },
  actions: {
    async setupOpenai({ commit }) {
      let apiKey = await axios.get("group_access");
      apiKey = apiKey.data.api_key;

      const configuration = new Configuration({
        apiKey: apiKey,
      });

      const openai = new OpenAIApi(configuration);
      commit("SET_OPENAI", openai);
    },
  },
};
