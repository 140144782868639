import axios from "axios";

export default {
  state: {
    data: null,
    access: null,
  },
  mutations: {
    SET_USER(state, payload) {
      state.data = payload;
    },
    SET_ACCESS(state, payload) {
      state.access = payload;
    },
  },
  actions: {
    async getUser({ commit }) {
      try {
        let user = await axios.get("user");
        user = user.data.data;

        let activeAccess = await axios.get("group_access");
        activeAccess = activeAccess.data.id;

        commit("SET_USER", user);
        commit("SET_ACCESS", activeAccess);
      } catch (e) {
        console.error(e);
      }
    },
  },
};
