<template>
  <div id="app">
    <div v-if="loading" class="loading">
      <b-spinner variant="primary" label="Loading"></b-spinner>
    </div>

    <main-nav />

    <router-view />
  </div>
</template>

<script>
import MainNav from "./components/MainNav.vue";

export default {
  components: { MainNav },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
};
</script>

<style lang="scss">
.loading {
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
