<template>
  <div class="main-nav">
    <b-container>
      <b-row align-h="between" align-v="center">
        <b-col cols="auto">
          <div class="main-nav-links">
            <a
              v-for="(item, index) in links"
              :key="index"
              class="main-nav-link"
              :class="[item.routePath == $route.path ? 'active' : '']"
              @click="
                () => {
                  if (item.routePath != $route.path)
                    $router.push({ path: item.routePath });
                }
              "
            >
              {{ item.name }}
            </a>
          </div>
        </b-col>

        <b-col cols="auto">
          <div>
            <a
              v-if="!user && !loading"
              @click="
                () => {
                  if ($route.name != 'Auth') $router.push({ name: 'Auth' });
                }
              "
              >Аккаунт</a
            >

            <a
              v-if="user"
              class="main-nav-link"
              :class="[$route.name == 'Profile' ? 'active' : '']"
              @click="
                () => {
                  if ($route.name != 'Profile')
                    $router.push({ name: 'Profile' });
                }
              "
              >{{ user.name }}</a
            >
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "MainNav",
  computed: {
    ...mapState({
      user: (state) => state.user.data,
      loading: (state) => state.loading,
    }),
  },
  data() {
    return {
      links: [
        { name: "ChatGPT", routePath: "/" },
        { name: "DALL-E", routePath: "/dalle" },
      ],
    };
  },
};
</script>

<style lang="scss">
.main-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  background-color: rgba($color: #1a1a1a, $alpha: 0.5);

  padding-top: 10px;
  padding-bottom: 10px;

  backdrop-filter: blur(10px);
}

.main-nav-padding {
  width: 100%;
  height: 10vh;
}

.main-nav-links {
  display: flex;
}

.main-nav-link {
  cursor: pointer;
  color: #f0f0f0;
  margin: 5px 10px;
}
.main-nav-link:hover {
  color: #fff;
}
.main-nav-link.active {
  font-weight: 600;
}
</style>
